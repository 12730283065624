export const createActionTypes = (base, actions = []) => {
  return actions.reduce((acc, type) => {
    acc[type] = `${base}_${type}`;
    return acc;
  }, {});
};

export const createAction = (type, data = {}) => {
  return {type, payload: data};
};
