import authConstant from 'constants/AuthConstant';

export const initialState = {
  error: '',
  loading: false,
  isAuthenticated: false,
  currentUser: null,
};

export const authReducer = (state = initialState, action) => {
  // All sending request from auth -> SENDING_REQUEST
  // All error request from auth -> REQUEST_ERROR
  // Login/Logout -> SET_AUTH
  switch (action.type) {
    case authConstant.SENDING_REQUEST:
    case authConstant.SET_AUTH:
    case authConstant.REQUEST_ERROR:
    case authConstant.CLEAR_ERROR: {
      return {...state, ...action.payload};
    }
    default:
      return state;
  }
};
