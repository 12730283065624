export const {
  REACT_APP_GOOGLE_API_BASE_URL,
  REACT_APP_CONNY_ON_AIR_BASE_URL,
  REACT_APP_CONNY_ON_AIR_AUTH_URL,
  REACT_APP_PANEL_UPLOAD_TAG,
  REACT_APP_PANEL_UPLOAD_CHECKLISTS_URL,
  REACT_APP_PANEL_UPLOAD_DRAWINGS_URL,
  REACT_APP_PANEL_DELETE_CHECKLISTS_URL,
  REACT_APP_PANEL_DELETE_DRAWINGS_URL,
  REACT_APP_PANEL_CHECKLIST_BUCKET_ID,
  REACT_APP_PANEL_DRAWINGS_BUCKET_ID,
} = process.env;
