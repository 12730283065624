import React from 'react';
import { authReducer, initialState } from 'reducers/AuthReducer';
import authAction from 'actions/AuthAction';
import { logout } from 'api/AuthAPI';
import { getLocalStorageValue, setHeaderToken, isTokenValid, JWT_TOKEN_KEY } from 'api/APIUtils';

const AuthContext = React.createContext({
  state: initialState,
  dispatch: () => initialState,
});

export function AuthProvider(props) {
  const [state, dispatch] = React.useReducer(authReducer, initialState);

  React.useEffect(() => {
    const token = getLocalStorageValue(JWT_TOKEN_KEY);
    const done2fa = getLocalStorageValue('done2fa');
    const userDo2fa = getLocalStorageValue('userDo2fa');

    console.log(done2fa, userDo2fa);

    if (!token) return;

    if (isTokenValid(token)) {
      setHeaderToken(token);
      if (userDo2fa) {
        done2fa && dispatch(authAction.setAuth({ isAuthenticated: true }));
      } else {
        dispatch(authAction.setAuth({ isAuthenticated: true }));
      }
    } else {
      logout();
    }
  }, []);

  return <AuthContext.Provider value={{ state, dispatch }} {...props} />;
}

export default function useAuth() {
  const context = React.useContext(AuthContext);
  if (!context) {
    throw new Error(`useAuth must be used within an AuthProvider`);
  }
  return context;
}
