/*
 * To pre-load the required font family with fontfaceobserver.
 *
 */

import FontFaceObserver from 'fontfaceobserver';

const fontData = {
  'Roboto': [
    { weight: 300 },
    { weight: 400 },
    { weight: 500 },
  ],
  // Etc.
};

var observers = [];

// Make one observer for each font,
// by iterating over the data we already have
Object.keys(fontData).forEach(function(family) {
  fontData[family].forEach(function(data) {
    const obs = new FontFaceObserver(family, data);
    observers.push(obs.load());
  });
});

Promise.all(observers)
  .then(function(fonts) {
    document.body.classList.add("fonts-loaded");
  }).catch(function(err) {
    document.body.classList.add("fonts-unavailable");
  });
