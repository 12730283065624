import axios from 'axios';
import jwtDecode from 'jwt-decode';

import { REACT_APP_CONNY_ON_AIR_BASE_URL, REACT_APP_CONNY_ON_AIR_AUTH_URL } from 'config';
export const JWT_TOKEN_KEY = 'token';
export const AUTH = axios.create({
  baseURL: REACT_APP_CONNY_ON_AIR_AUTH_URL,
  headers: { common: { 'web-name': 'onair' } },
});

axios.defaults.baseURL = REACT_APP_CONNY_ON_AIR_BASE_URL;

const cleanLocalStorage = async () => {
  localStorage.removeItem(JWT_TOKEN_KEY);
  localStorage.removeItem('userDo2fa');
  localStorage.removeItem('done2fa');
  setHeaderToken(null);
};

axios.interceptors.response.use(
  (response) => {
    return response;
  },
  (error) => {
    switch (error?.response?.status) {
      case 401:
        const redirectUrl = error?.response?.data?.url;
        if (redirectUrl === '') {
          return;
        }
        if (redirectUrl) {
          window.location.pathname = redirectUrl;
          return;
        }
        cleanLocalStorage();
        window.location.reload();
        break;
      case 403:
        // navigate('/403');
        break;
      default:
        break;
    }
    return Promise.reject(error.response);
  }
);

export function setHeaderToken(token) {
  if (token) {
    axios.defaults.headers.common['Authorization'] = `Bearer ${token}`;
  } else {
    delete axios.defaults.headers.common['Authorization'];
  }
}

export function isTokenValid(token) {
  try {
    const decoded_jwt = jwtDecode(token);
    const current_time = Date.now().valueOf() / 1000;
    return decoded_jwt.exp > current_time;
  } catch (error) {
    return false;
  }
}

export function getLocalStorageValue(key) {
  const value = localStorage.getItem(key);
  if (!value) return null;
  try {
    return JSON.parse(value);
  } catch (error) {
    return null;
  }
}

export function setLocalStorage(key, value) {
  localStorage.setItem(key, JSON.stringify(value));
}

export default axios;
