import React from 'react';
import { navigate } from '@reach/router';

// Material UI
import { Button, Box, Container, Paper } from '@material-ui/core';

// Redux
import useAuth from 'contexts/AuthContext';
import authAction from 'actions/AuthAction';

// API
import { checkAuth, setToken } from 'api/AuthAPI';
import { JWT_TOKEN_KEY, getLocalStorageValue, setLocalStorage } from 'api/APIUtils';

// Configs
import { REACT_APP_CONNY_ON_AIR_BASE_URL } from 'config';

// Assets, styles, custom UI
import Image from 'components/Image';
import Copyright from 'components/Copyright';
import useStyles from './style';
import OtpModal from 'components/OtpModal/OtpModal';
import { AUTH_CONSTANTS } from 'constants/2faConstant';

const Saml = () => {
  const classes = useStyles();
  const {
    dispatch,
    state: { user },
  } = useAuth();

  const { AUTH_APP, EMAIL, LOCAL, SAML } = AUTH_CONSTANTS;

  // state
  const [authState, setAuthState] = React.useState(LOCAL);
  const [remainingTime, setRemainingTime] = React.useState(0);

  // Retrieve URL-encoded values from the URL
  const urlParams = new URLSearchParams(window.location.search);
  const isAuthApp2fa = urlParams.get('isAuthApp2fa') === 'true';
  const isEmail2fa = urlParams.get('isEmail2fa') === 'true';
  const userDo2fa = urlParams.get('userDo2fa') === 'true';
  const userId = urlParams.get('userId');
  const token = urlParams.get('token');

  const RedirectToLogin = async () => {
    window.location.replace(
      `${REACT_APP_CONNY_ON_AIR_BASE_URL}/saml/login?domainName=${encodeURIComponent(
        `${window.location.origin}/login/saml/st`
      )}`
    );
  };

  const authCheck = async () => {
    try {
      const authUser = await checkAuth();
      return authUser;
    } catch (error) {
      console.log(error);
    }
  };

  React.useEffect(() => {
    const checkAndSetAuth = async () => {
      try {
        if (userDo2fa) {
          setLocalStorage('userDo2fa', true);
          dispatch(authAction.sendingRequest({ loading: true }));
          if (isEmail2fa && isAuthApp2fa) {
            setAuthState(AUTH_APP);
          } else if (isAuthApp2fa) {
            setAuthState(AUTH_APP);
          } else if (isEmail2fa) {
            setAuthState(EMAIL);
          }
        } else {
          setLocalStorage('userDo2fa', false);
          if (getLocalStorageValue(JWT_TOKEN_KEY) && token) {
            dispatch(authAction.sendingRequest({ loading: true }));
            const authUser = await authCheck();
            const auth = {
              user: authUser,
              isAuthenticated: true,
            };
            dispatch(authAction.setAuth({ ...auth }));
            dispatch(authAction.sendingRequest({ loading: false }));
            dispatch(authAction.clearError());
          }
        }
      } catch (response) {
        console.log(response);
        const { data } = response;
        console.log({ response });
        dispatch(authAction.sendingRequest({ loading: false }));
        dispatch(authAction.failure({ error: data.message }));
      }
    };
    checkAndSetAuth();
  }, [isAuthApp2fa, isEmail2fa, userDo2fa, getLocalStorageValue(JWT_TOKEN_KEY)]);

  React.useEffect(() => {
    let timer;

    // Start the timer
    timer = setInterval(() => {
      setRemainingTime(remainingTime - 1);
    }, 1000); // Update every second

    // Clean up the timer on component unmount
    return () => clearInterval(timer);
  }, [remainingTime]);

  React.useEffect(() => {
    user && navigate('/dashboard');
  }, [user]);

  React.useEffect(() => {
    if (token) {
      setToken(token);
    }
  }, [token]);

  return (
    <Container component="main" maxWidth="xs">
      <Paper className={classes.paper}>
        <OtpModal
          authState={authState}
          setAuthState={setAuthState}
          userId={userId}
          remainingTime={remainingTime}
          setRemainingTime={setRemainingTime}
          authType={SAML}
        />
        <Image className={classes.brand} />
        <Button
          fullWidth
          variant="contained"
          color="primary"
          className={classes.submit}
          onClick={RedirectToLogin}
        >
          Sign In With ST
        </Button>
      </Paper>
      <Box mt={8} className={classes.footer}>
        <Copyright />
      </Box>
    </Container>
  );
};

export default Saml;
