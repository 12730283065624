// React
import React from 'react';
import { navigate } from '@reach/router';

// Redux
import useAuth from '../../contexts/AuthContext';
import authAction from '../../actions/AuthAction';

// Components
import {
  Button,
  Card,
  CardContent,
  CircularProgress,
  Grid,
  Input,
  Link,
  Modal,
  Typography,
} from '@material-ui/core';

// API
import {
  generateEmailToken2faAPI,
  requestOtpAPI,
  verifyEmail2faOnairAPI,
  verifyOtpAuthenticatorAppOnairAPI,
} from '../../api/AuthAPI';

// styles
import useStyles from './style';
import { setLocalStorage } from 'api/APIUtils';
import { AUTH_CONSTANTS } from 'constants/2faConstant';

const OtpModal = ({ authState, setAuthState, userId, remainingTime, setRemainingTime }) => {
  // redux
  const {
    state: { loading, error },
    dispatch,
  } = useAuth();

  // global var
  const { AUTH_APP, EMAIL, LOCAL } = AUTH_CONSTANTS;

  // states
  const [otp, setOTP] = React.useState(['', '', '', '', '', '']);
  const [modalState, setModalState] = React.useState(false);

  // style
  const classes = useStyles();

  // handle different type of otp verification function
  const handleOtpVerificationFunction = () => {
    if (authState === AUTH_APP) {
      return verifyOtpAuthenticatorAppOnairAPI({ userId: userId, token: otp.join('') });
    }

    if (authState === EMAIL) {
      return verifyEmail2faOnairAPI({ userId: userId, token: otp.join('') });
    }
  };

  // submitting otp
  const handleSubmitOtp = async () => {
    try {
      dispatch(authAction.sendingRequest({ loading: true }));
      const authUser = await handleOtpVerificationFunction();
      if (authUser) {
        const auth = {
          user: authUser.data,
          isAuthenticated: true,
        };
        setLocalStorage('done2fa', true);
        dispatch(authAction.setAuth({ ...auth }));
        dispatch(authAction.sendingRequest({ loading: false }));
        dispatch(authAction.clearError());
        navigate('/dashboard');
      }
    } catch (error) {
      console.log(error);
      const { data } = error;
      console.log({ error });
      dispatch(authAction.sendingRequest({ loading: false }));
      dispatch(authAction.failure({ error: data?.message }));
    }
  };

  // handle otp changes
  const handleOTPChange = (index, e) => {
    const { value } = e.target;
    if (value.match(/^\d*$/)) {
      if (value !== '') {
        const newOTP = [...otp];
        newOTP[index] = value;
        setOTP(newOTP);
        if (index < 5 && value) {
          // Move focus to the next input field if a digit is entered
          const nextInput = document.getElementById(`otp-input-${index + 1}`);

          if (nextInput) {
            nextInput.focus();
          }
        }
      }
    }
  };

  // handle key down of otp input
  const handleKeyDown = (index, e) => {
    if ((e.key === 'Delete' || e.key === 'Backspace') && index >= 0) {
      // If "Delete" is pressed and the current input is not the first one, delete the value and move focus backward
      const newOTP = [...otp];
      const haveValue = newOTP[index];
      newOTP[index] = '';
      setOTP(newOTP);
      console.log(haveValue);
      if (haveValue === '') {
        newOTP[index - 1] = '';
        index !== 0 && document.getElementById(`otp-input-${index - 1}`).focus();
      }
    }

    if (e.key === 'Enter') {
      handleSubmitOtp();
    }
  };

  // handle from AuthApp2fa -> Email2fa
  const useEmailInstead = async () => {
    setAuthState(EMAIL);
  };

  // handle requesting for another otp
  const handleRequestOtp = async () => {
    dispatch(authAction.sendingRequest({ loading: true }));
    await requestOtpAPI({ userId: userId });
    setRemainingTime(60);
    dispatch(authAction.sendingRequest({ loading: false }));
  };

  // trigger generate email token
  const triggerEmail2fa = async () => {
    dispatch(authAction.sendingRequest({ loading: true }));
    const response = await generateEmailToken2faAPI({ userId: userId });
    console.log(response);
    response.status === 201 ? setRemainingTime(15) : setRemainingTime(60);
    dispatch(authAction.sendingRequest({ loading: false }));
  };

  // return Otp related informations
  const returnOtpInputProps = () => {
    let otpInputProps;

    switch (authState) {
      case AUTH_APP:
        otpInputProps = {
          Text: 'Please enter your one-time password (OTP) code generated by your identity verification app',
          useEmail: (
            <Grid item xs={12}>
              <Link component="button" variant="body2" onClick={useEmailInstead}>
                Use email instead...
              </Link>
            </Grid>
          ),
        };
        return otpInputProps;
      case EMAIL:
        otpInputProps = {
          Text: 'Please enter your one-time password (OTP) sent to your email',
          requestNewOtp: (
            <Grid item xs={12} style={{ justifyContent: 'center' }}>
              <Link
                component="button"
                variant="body2"
                onClick={handleRequestOtp}
                disabled={loading ? true : remainingTime >= 0}
                color={loading ? 'initial' : remainingTime >= 0 ? 'initial' : 'primary'}
              >
                {remainingTime >= 0
                  ? `Request a new OTP in ${remainingTime} ${
                      remainingTime <= 1 ? 'second' : 'seconds'
                    }`
                  : 'Request a new OTP'}
              </Link>
            </Grid>
          ),
        };
        return otpInputProps;
      default:
        return;
    }
  };

  // set states to default
  const handleCloseModal = () => {
    setModalState(false);
    setAuthState(LOCAL);
    dispatch(authAction.sendingRequest({ loading: false }));
    dispatch(authAction.failure({ error: '' }));
  };

  React.useEffect(() => {
    setTimeout(() => {
      // eslint-disable-next-line no-unused-expressions
      document?.getElementById(`otp-input-0`)?.focus();
    }, 250);
  }, [modalState]);

  React.useEffect(() => {
    switch (authState) {
      case AUTH_APP:
        setModalState(true);
        break;
      case EMAIL:
        triggerEmail2fa();
        setModalState(true);
        break;
      default:
        break;
    }
  }, [authState]);

  return (
    <div>
      <Modal open={modalState} onClose={handleCloseModal} maxWidth={800}>
        <div className={classes.modalStyle}>
          <Grid container spacing={3} justifyContent="center" alignItems="center">
            <Grid item xs={12}>
              <Typography variant="h5" align="left">
                {authState === AUTH_APP ? 'Authentication App OTP' : 'Email OTP'}
              </Typography>
              <Typography variant="h7" align="left">
                {returnOtpInputProps()?.Text}
              </Typography>
            </Grid>
            <Grid item xs={12}>
              <Typography color="error" variant="subtitle1" align="center">
                {error}
              </Typography>
            </Grid>
            <Grid item xs={12}>
              <Card elevation={3} sx={{ width: '300px' }}>
                <CardContent>
                  <Grid
                    container
                    spacing={2}
                    justifyContent="center"
                    alignItems="center"
                    justify="center"
                  >
                    {otp.map((digit, index) => (
                      <Grid item key={index}>
                        <Input
                          id={`otp-input-${index}`}
                          value={digit}
                          onChange={(e) => handleOTPChange(index, e)}
                          onKeyDown={(e) => handleKeyDown(index, e)}
                          inputProps={{
                            maxLength: 1,
                            style: {
                              width: '20px',
                              height: '20px',
                              fontSize: '20px',
                              textAlign: 'center',
                              margin: '5px',
                            },
                          }}
                          autoFocus={index === 0} // Auto-focus on the first digit
                        />
                      </Grid>
                    ))}
                  </Grid>
                </CardContent>
              </Card>
            </Grid>
            <Grid item xs={12} spacing={8}>
              <Button
                variant="contained"
                color="primary"
                onClick={handleSubmitOtp}
                fullWidth
                disabled={loading}
              >
                {loading ? <CircularProgress /> : 'Submit'}
              </Button>
            </Grid>
            {returnOtpInputProps()?.useEmail}
            {returnOtpInputProps()?.requestNewOtp}
          </Grid>
        </div>
      </Modal>
    </div>
  );
};

export default OtpModal;
