import API, {
  setLocalStorage,
  setHeaderToken,
  JWT_TOKEN_KEY,
  AUTH,
  getLocalStorageValue,
} from './APIUtils';

const handleUserResponse = (response) => {
  setLocalStorage(JWT_TOKEN_KEY, response.data.token);
  setHeaderToken(response.data.token);

  return { data: response.data, status: response.status };
};

export const setToken = (token) => {
  setLocalStorage(JWT_TOKEN_KEY, token);
  setHeaderToken(token);
};

export const getCurrentUser = () => {
  return API.get(`/user`);
};

export const login = async (email, password) => {
  const response = await AUTH.post('/authentication', {
    strategy: 'local',
    email,
    password,
  });

  return handleUserResponse(response);
};

export const verifyOtpAuthenticatorAppAPI = async (form) => {
  const { data } = await AUTH.post('/authentication/verify-authenticatorApp-2fa', {
    ...form,
  });

  return data;
};

export const verifyOtpAuthenticatorAppOnairAPI = async (form) => {
  const { data } = await API.post('/authentication/verify-authenticatorApp-2fa', {
    ...form,
  });

  return data;
};

export const verifyEmail2faOnairAPI = async (form) => {
  const { data } = await API.post('/authentication/verify-email-2fa', {
    ...form,
  });

  return data;
};

export const verifyEmail2faAPI = async (form) => {
  const { data } = await AUTH.post('/authentication/verify-email-2fa', {
    ...form,
  });

  return data;
};

export const generateEmailToken2faAPI = async (form) => {
  const response = await API.patch('/generateToken-email-2fa', {
    ...form,
  });
  return response;
};

export const requestOtpAPI = async (form) => {
  const { data } = await API.patch('/request-otp', {
    ...form,
  });
  return data;
};

export const sendResetPasswordTo = async (email) => {
  const response = await API.post('/sendReset', { email });
  return response;
};

export const resetPassword = async (token, newPassword) => {
  const response = await API.post('/resetpassword', {
    token,
    newPassword,
  });
  return response;
};

export const logout = async () => {
  const token = getLocalStorageValue('token');

  // destroy session
  if (!token) {
    const response = await API.get('/saml/logout');
    if (response.status === 200) {
      window.location.replace(`${window.location.origin}/login/saml/${response.data.who}`);
      return;
    }
  } else {
    // logout local
    localStorage.removeItem(JWT_TOKEN_KEY);
    localStorage.removeItem('userDo2fa');
    localStorage.removeItem('done2fa');
    setHeaderToken(null);
  }
};

export const checkAuth = async () => {
  const { data } = await API.get('/saml/auth-check');

  return data;
};

export const getSamlClient = async () => {
  const { data } = await API.get('/saml/client');
  return data;
};
