import React from 'react';
// thrid-party pacakges
import { Router } from '@reach/router';
// global state, action, API
import { getCurrentUser } from 'api/AuthAPI';
import useAuth, { AuthProvider } from 'contexts/AuthContext';
import authAction from 'actions/AuthAction';
// material UI
import CssBaseline from '@material-ui/core/CssBaseline'; // reset css
import { MuiPickersUtilsProvider } from '@material-ui/pickers';
import MomentUtils from '@date-io/moment';
// custom packages
import PrivateRoute from './PrivateRoute';
import CircularProgress from '@material-ui/core/CircularProgress';
import GlobalStyle from './style';
import { MuiThemeProvider, createMuiTheme } from '@material-ui/core/styles';
import 'utils/loadFont';
import Saml from 'routes/Saml';

// routes
const Layout = React.lazy(() => import('routes/Layout'));
const Login = React.lazy(() => import('routes/Login'));
const Dashboard = React.lazy(() => import('routes/Dashboard'));
const SendResetPassword = React.lazy(() => import('routes/SendResetPassword'));
const ResetPassword = React.lazy(() => import('routes/ResetPassword'));
const Users = React.lazy(() => import('routes/Users'));
const AuditLog = React.lazy(() => import('routes/AuditLog'));
const Logout = React.lazy(() => import('routes/Logout'));
const Posts = React.lazy(() => import('routes/Posts'));
const Company = React.lazy(() => import('routes/Company'));
const CompanyDetails = React.lazy(() => import('routes/CompanyDetails'));
const CompanySite = React.lazy(() => import('routes/CompanySite'));
const CompanyLocation = React.lazy(() => import('routes/CompanyLocation'));
const CompanyFeature = React.lazy(() => import('../../routes/CompanyFeature'));
const Equipment = React.lazy(() => import('routes/Equipment'));
const System = React.lazy(() => import('routes/Systems'));
const Environment = React.lazy(() => import('routes/Environment'));
const Pump = React.lazy(() => import('routes/Pump'));
const Panel = React.lazy(() => import('routes/Panel'));
const Power = React.lazy(() => import('routes/Power'));
const PanelForm = React.lazy(() => import('routes/PanelForm'));
const NotFound = React.lazy(() => import('routes/NotFound'));

// use default theme
// const theme = createMuiTheme();

// Or Create your Own theme:
const theme = createMuiTheme({
  palette: {
    primary: {
      main: 'rgb(22, 84, 135)',
    },
    // secondary: {
    //   main: '#E33E7F',
    // },
  },
});

const App = () => {
  const {
    state: { currentUser, isAuthenticated, loading, error },
    dispatch,
  } = useAuth();

  React.useEffect(() => {
    let ignore = false;
    const fetchUser = async () => {
      dispatch(authAction.sendingRequest({ loading: true }));

      try {
        const payload = await getCurrentUser();
        const { currentUser } = payload.data;

        if (!ignore) {
          dispatch(authAction.setAuth({ currentUser, isAuthenticated: true }));
          dispatch(authAction.sendingRequest({ loading: false }));
        }
      } catch (error) {
        if (!ignore) {
          dispatch(authAction.failure(error));
        }
      }
    };

    if (!currentUser && isAuthenticated) {
      fetchUser();
    }

    return () => {
      ignore = true;
    };
  }, [dispatch, isAuthenticated, currentUser]);

  if (!currentUser && isAuthenticated) {
    return null;
  }

  return (
    <React.Fragment>
      <MuiPickersUtilsProvider utils={MomentUtils}>
        <MuiThemeProvider theme={theme}>
          <CssBaseline />
          <React.Suspense
            fallback={
              <div
                style={{
                  height: '100vh',
                  display: 'flex',
                  alignItems: 'center',
                  justifyContent: 'center',
                }}
              >
                <CircularProgress />
              </div>
            }
          >
            <Router>
              <Login path="/login" />
              <SendResetPassword path="/reset-password" />
              <ResetPassword path="/reset-password/:accessToken" />
              <Saml path="/login/saml/st" />
              <PrivateRoute as={Layout} path="/">
                <Dashboard path="dashboard" />
                <Posts path="posts" />
                <Users path="users" />
                <Company path="company" />
                <CompanyDetails path="company/:companyId" />
                <CompanySite path="company/:companyId/:siteId" />
                <CompanyLocation path="company/:companyId/:siteId/:locationId" />
                <CompanyFeature path="company/feature" />
                <Equipment path="equipment" />
                <System path="systems" />
                <Panel path="panel" />
                <PanelForm path="panel/form" />
                <PanelForm path="panel/form/:panelId" />
                <Environment path="environment" />
                <Pump path="pump" />
                <Power path="power" />
                <AuditLog path="audit-log" />
                <Logout path="logout" />
                <NotFound default />
              </PrivateRoute>
            </Router>
          </React.Suspense>
          <GlobalStyle />
        </MuiThemeProvider>
      </MuiPickersUtilsProvider>
    </React.Fragment>
  );
};

export default () => (
  <AuthProvider>
    <App />
  </AuthProvider>
);
