import {createAction} from 'utils/types';
import authConstant from 'constants/AuthConstant';

const authAction = {
  setAuth: data => createAction(authConstant.SET_AUTH, {...data}),
  login: data => createAction(authConstant.LOGIN, {...data}),
  logout: () => createAction(authConstant.LOGOUT),
  sendingRequest: loading => createAction(authConstant.SENDING_REQUEST, {...loading}),
  failure: error => createAction(authConstant.REQUEST_ERROR, {...error}),
  clearError: () => createAction(authConstant.CLEAR_ERROR, {error: ''}),
};

export default authAction;
