import React from 'react';
// assets, styles, custom UI
import Brand from 'assets/images/ConnyOnAirLogo.png';

export default function Image(props) {
  return (
    <img
      {...props}
      src={props.src ? props.src : Brand}
      alt={props.alt ? props.alt : 'Brand Image'}
    />
  )
}
