import {createActionTypes} from 'utils/types';

const authConstant = createActionTypes('AUTH', [
  'SENDING_REQUEST',
  'SET_AUTH',
  'REQUEST_ERROR',
  'CLEAR_ERROR',
]);

export default authConstant;
