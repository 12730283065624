import React from 'react';

// Material UI
import Typography from '@material-ui/core/Typography';
import Link from '@material-ui/core/Link';

// styles, assets
import useStyles from './style';

export default function Copyright() {
  const classes = useStyles();

  return (
    <Typography
      variant="body2"
      color="textSecondary"
      align="center"
      className={classes.footer}>
      {`${process.env.REACT_APP_ENV}  |  Copyright © `}
      <Link
        color="inherit"
        href="http://www.conny.sg"
        target="_blank"
        rel="noreferrer noopener">
        Conny 
      </Link>
      {new Date().getFullYear()}
      {'.'}
    </Typography>
  );
}
