import React from 'react';
import {Redirect} from '@reach/router';
import useAuth from 'contexts/AuthContext';

const PrivateRoute = ({as: Comp, ...props}) => {
  const from =
    props.location.pathname !== '/logout'
      ? `?from=${props.location.pathname}`
      : '';
  const {
    state: {currentUser},
  } = useAuth();
  return currentUser ? (
    <Comp {...props} />
  ) : (
    <Redirect to={`/login${from}`} noThrow />
  );
};

export default PrivateRoute;
